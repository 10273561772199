// extracted by mini-css-extract-plugin
export var blogNews = "index-module--blogNews--Vrckn";
export var box = "index-module--box--0KB2O";
export var category = "index-module--category--qaae8";
export var content = "index-module--content--J8shd";
export var cover = "index-module--cover--ee+-V";
export var date = "index-module--date--Oz5y3";
export var description = "index-module--description--vJ11G";
export var detail = "index-module--detail--wmo1z";
export var head = "index-module--head--5sX0c";
export var lists = "index-module--lists--geAiQ";
export var look = "index-module--look--D-tub";
export var more = "index-module--more--Z2aYV";
export var news = "index-module--news--P0bP2";
export var protfolio = "index-module--protfolio--SN+IU";
export var text = "index-module--text--O7Hhk";
export var title = "index-module--title--6Sf4B";
export var top = "index-module--top--RkqBB";
export var work = "index-module--work--AmQPU";